import React, { useState, useEffect } from 'react';
import { Canvas, useLoader, useFrame, useThree } from '@react-three/fiber';
import {OrbitControls, Html, PerspectiveCamera} from '@react-three/drei';
import * as THREE from 'three';

function Globe({ coordinates }) {
  const [visibleImageIndices, setVisibleImageIndices] = useState([]); // Indices of nearby points
  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(0);  // Currently shown image index
  const [rotationInterval, setRotationInterval] = useState(null);  // Interval for cycling through images
  const [lastClickedIndex, setLastClickedIndex] = useState(null);  // Store the last clicked point

  // Load the texture from the server
  const earthTexture = useLoader(THREE.TextureLoader, 'https://backendserver.org/get_map');

  const { camera } = useThree();

  // Function to check if two points are close
  const arePointsClose = (point1, point2, threshold = 0.01) => {
    const distance = new THREE.Vector3(...point1).distanceTo(new THREE.Vector3(...point2));
    return distance < threshold;
  };

  // Start cycling through images of nearby points every 1.5 seconds
  useEffect(() => {
    if (visibleImageIndices.length > 1 && rotationInterval === null) {
      const interval = setInterval(() => {
        setCurrentVisibleIndex((prevIndex) => (prevIndex + 1) % visibleImageIndices.length); // Rotate between points
      }, 1500); // Change image every 1.5 seconds
      setRotationInterval(interval);
    }

    return () => {
      if (rotationInterval) {
        clearInterval(rotationInterval);
        setRotationInterval(null);
      }
    };
  }, [visibleImageIndices, rotationInterval]);

  // Handle point click and toggle nearby points
  const handlePointClick = (clickedIndex) => {
    // If the same point is clicked, hide the images by clearing the visible indices
    if (clickedIndex === lastClickedIndex) {
      setVisibleImageIndices([]);
      setLastClickedIndex(null);  // Reset the last clicked point
      return;
    }

    // Otherwise, find points that are close to the clicked point
    const clickedPoint = coordinates[clickedIndex];
    const nearbyPoints = coordinates
      .map((coord, index) => arePointsClose(coord, clickedPoint) ? index : null)
      .filter(index => index !== null);

    // Set the points to cycle through and start from the first image
    setVisibleImageIndices(nearbyPoints);
    setCurrentVisibleIndex(0);
    setLastClickedIndex(clickedIndex);  // Set the last clicked point
  };

  return (
    <>
      <OrbitControls minDistance={10.1} maxDistance={100} />
        <ambientLight intensity={0.1}/>
        {/* Reduce ambient light for more contrast */}
        <directionalLight
            position={[6.51234340563848, 7.522106032495888, -1.0036454567854074]} // Adjust the position of the light to simulate the sun's angle
            intensity={2.0} // Brightness of the light
            castShadow={true} // Enable shadows if needed
        />

        {/* Globe with world map texture loaded from the server */}
      <mesh>
        <sphereGeometry args={[10, 64, 64]} />
        <meshStandardMaterial map={earthTexture} />
      </mesh>

      {coordinates.map((coord, index) => (
        <mesh
          key={index}
          position={coord}
          onClick={() => handlePointClick(index)}  // Handle click to toggle images
        >
          <sphereGeometry args={[0.05, 16, 16]} />
          <meshStandardMaterial color={'#FF0000'} />

          {/* Show image if this point is part of the nearby points and its turn in the cycle */}
          {visibleImageIndices.includes(index) && visibleImageIndices[currentVisibleIndex] === index && (
            <Html position={[0, 0, 0]} className="image-overlay">
              <img
                src={`https://backendserver.org/get_thumbnail/${String(index + 1).padStart(3, '0')}.jpg`}
                alt={`Dot ${index}`}
                style={{
                  width: '9vw',
                  minWidth: '50px',
                  height: 'auto',
                  border: `2px solid #FF0000`,
                  borderRadius: '4px',
                  background: '#fff',
                }}
              />
            </Html>
          )}
        </mesh>
      ))}
    </>
  );
}

export function Map() {
/*  const [coordinates, setCoordinates] = useState([]);

  useEffect(() => {
    // Fetch coordinates from 'testtest/coordinates'
    fetch('https://backendserver.org/get_map/coordinates')
      .then(response => response.json())
      .then(data => {

        setCoordinates(data); // Assuming the server returns an object with a 'coordinates' array
      })
      .catch(error => {
        console.error('Error fetching coordinates:', error);
      });
  }, []);*/
  const coordinates = [
      [6.51234340563848, 7.522106032495888, -1.0036454567854074],   // Neuhausen
      [4.5827678872310855, 8.854807240238872, -0.7685227582686196], // Dovrefjell
      [4.5827678872310855, 8.854807240238872, -0.7685227582686196], // Dovrefjell
      [4.5827678872310855, 8.854807240238872, -0.7685227582686196], // Dovrefjell
      [4.646004549559178, 8.820494638875115, -0.7832726543489176],  // Rondane
      [6.614086811005637, 7.446964199591195, -0.8925132304276173],  // Kaiserstuhl
      [6.614086811005637, 7.446964199591195, -0.8925132304276173],  // Kaiserstuhl
      [6.51234340563848, 7.522106032495888, -1.0036454567854074],   // Neuhausen
      [5.122379495867744, 8.57045669663766, -0.5554280433795096],   // Preikestolen
      [5.122379495867744, 8.57045669663766, -0.5554280433795096],   // Preikestolen
      [4.5827678872310855, 8.854807240238872, -0.7685227582686196], // Dovrefjell
      [4.5827678872310855, 8.854807240238872, -0.7685227582686196], // Dovrefjell
      [4.062347574742868, 9.084647229376051, -0.9831159137003072],  // Borgefjell
      [4.062347574742868, 9.084647229376051, -0.9831159137003072],  // Borgefjell
      [3.69169940593141, 9.23441467506775, -1.0473495620560744],    // Rago
      [3.660435290525837, 9.267090603896147, -0.849850117993043],   // Reinbringen
      [3.660435290525837, 9.267090603896147, -0.849850117993043],   // Lofoten?
      [3.40890281838316, 9.350624891139972, -0.9722117670615429],   // Vesteralen
      [3.4091261558785413, 9.350882983644285, -0.968940802885581],  // Vesteralen
      [2.9291859082810565, 9.407403486536264, -1.7089849490949394], // Varanger
      [2.9291859082810565, 9.407403486536264, -1.7089849490949394], // Varanger
      [3.210023470260053, 9.32553039565406, -1.6523414175381108],   // Lappland
      [3.471430167040959, 9.210969774039183, -1.7627275504440651],  // Pyhä-Luoston
      [3.6493641781335007, 9.072775212979236, -2.089710752737227],  // Martinselkonen
      [3.6493641781335007, 9.072775212979236, -2.089710752737227],  // Martinselkonen
      [3.6493641781335007, 9.072775212979236, -2.089710752737227],  // Martinselkonen
      [3.6493641781335007, 9.072775212979236, -2.089710752737227],  // Martinselkonen
      [3.8886066070203262, 8.919011145019423, -2.3086746957631727], // Patvinsuo
      [4.316578368158751, 8.824981055866187, -1.8673137270279068],  // Iso Kivijärvi
      [5.00059148179852, 8.38215977766128, -2.1756567500231876],    // Kemeru
      [5.011700393358784, 8.403335358687768, -2.065626785417768],   // Lettland
      [5.000548531383629, 8.381090047439713, -2.1798724737892448],  // Kemeru
      [4.999392237580489, 8.381861853510879, -2.179557093420391],   // Kemeru
      [6.821614506946955, 7.059214437943794, -1.9060606070470931],  // Plitvicka jezera
      [6.910766468523614, 6.948311745608928, -1.9905453281356484],  // Blusica Buk
      [6.953373740349414, 6.864605207542765, -2.127860186101505],   // Bast
      [6.937299248678675, 6.878812009712755, -2.134437740791207],   // Prolosko Blato
      [7.051974835189248, 6.935370576837024, -1.4731890190334849],  // Foreste Casentinesi
      [7.051974835189248, 6.935370576837024, -1.4731890190334849],  // Foreste Casentinesi
      [7.051974835189248, 6.935370576837024, -1.4731890190334849],  // Foreste Casentinesi
      [6.933067680857091, 7.0468098416567395, -1.5083247621731197], // Delta del Po
      [6.933348851982578, 7.0470355261791084, -1.505976091935524],  // Delta del Po
      [6.933348851982578, 7.0470355261791084, -1.505976091935524],  // Delta del Po
      [6.933348851982578, 7.0470355261791084, -1.505976091935524],  // Delta del Po
      [6.690167926517275, 7.250689139574793, -1.6337564433695981],  // Planica
      [6.5873660370311855, 7.440511929104505, -1.1159708450589603], // Federsee
      [6.5873660370311855, 7.440511929104505, -1.1159708450589603], // Federsee
      [6.5873660370311855, 7.440511929104505, -1.1159708450589603], // Federsee
      [6.512042484669169, 7.522410119522816, -1.0033188284710568],  // Monbachtal
      [6.614086811005637, 7.446964199591195, -0.8925132304276173],  // Kaiserstuhl
      [6.509135666821856, 7.524472739990749, -1.0067089231966206],  // Steinegg
      [6.509135666821856, 7.524472739990749, -1.0067089231966206],  // Steinegg
      [6.509135666821856, 7.524472739990749, -1.0067089231966206],  // Steinegg
      [6.513606804664858, 7.520515416093263, -1.0073600500949242],  // Möttlingen
      [6.511103938400779, 7.523011101534095, -1.0049027164540025],  // Neuhausen
      [6.5115777839919184, 7.52226616660291, -1.007405817833141],   // Büchelberg
      [6.5873660370311855, 7.440511929104505, -1.1159708450589603], // Federsee
      [6.5873660370311855, 7.440511929104505, -1.1159708450589603], // Federsee
      [6.511103938400779, 7.523011101534095, -1.0049027164540025],  // Neuhausen
      [6.509180086307195, 7.524810323115782, -1.0038945189371389],  // Neuhausen
      [6.511103938400779, 7.523011101534095, -1.0049027164540025],  // Neuhausen
      [6.512042484669169, 7.522410119522816, -1.0033188284710568],  // Monbachtal
      [6.512042484669169, 7.522410119522816, -1.0033188284710568],  // Monbachtal
      [6.512042484669169, 7.522410119522816, -1.0033188284710568],  // Monbachtal
      [6.509180086307195, 7.524810323115782, -1.0038945189371389],  // Neuhausen
      [6.511103938400779, 7.523011101534095, -1.0049027164540025],  // Neuhausen
      [6.5115777839919184, 7.52226616660291, -1.007405817833141],   // Büchelberg
      [6.511103938400779, 7.523011101534095, -1.0049027164540025],  // Neuhausen
      [6.513606804664858, 7.520515416093263, -1.0073600500949242],  // Möttlingen
      [6.513606804664858, 7.520515416093263, -1.0073600500949242],  // Möttlingen
      [6.513606804664858, 7.520515416093263, -1.0073600500949242],  // Möttlingen
      [6.513606804664858, 7.520515416093263, -1.0073600500949242],  // Möttlingen
      [1.9903835055267212, 9.78914539424564, -0.4593538410055412],  // Svalbard
      [1.8292113076828982, 9.824039735286496, -0.3771329624377108], // Svalbard
      [1.488897713304505, 9.876349984773093, -0.49081012376390215], // Svalbard
      [1.488897713304505, 9.876349984773093, -0.49081012376390215], // Svalbard
      [1.488897713304505, 9.876349984773093, -0.49081012376390215], // Svalbard
      [1.5659092746373884, 9.84416936189794, -0.8001610574593807],  // Svalbard
      [1.8412524487303716, 9.801082589749441, -0.7406547704259964], // Svalbard
      [1.8412524487303716, 9.801082589749441, -0.7406547704259964], // Svalbard
      [1.7147722277919557, 9.835235462702515, -0.5724505218726315], // Svalbard
      [1.7147722277919557, 9.835235462702515, -0.5724505218726315], // Svalbard
      [1.9475676022927142, 9.778626343909169, -0.7651452546447784], // Svalbard
      [1.9475676022927142, 9.778626343909169, -0.7651452546447784], // Svalbard
      [1.9475676022927142, 9.778626343909169, -0.7651452546447784], // Svalbard
      [1.9940033720838137, 9.78593229123708, -0.5093915423736376],  // Svalbard
      [1.9653386432646003, 9.78954667349729, -0.5497453453272696],  // Longyearbyen
      [1.9653386432646003, 9.78954667349729, -0.5497453453272696],  // Longyearbyen
      [6.512042484669169, 7.522410119522816, -1.0033188284710568],  // Monbachtal
      [6.513606804664858, 7.520515416093263, -1.0073600500949242],  // Möttlingen
      [6.512042484669169, 7.522410119522816, -1.0033188284710568],  // Monbachtal
      [6.512042484669169, 7.522410119522816, -1.0033188284710568],  // Monbachtal
      [6.513606804664858, 7.520515416093263, -1.0073600500949242],  // Möttlingen
      [6.509180086307195, 7.524810323115782, -1.0038945189371389],  // Neuhausen
      [6.513606804664858, 7.520515416093263, -1.0073600500949242],  // Möttlingen
      [6.513606804664858, 7.520515416093263, -1.0073600500949242],  // Möttlingen
      [6.738765480902143, 7.2545879890301155, -1.3999974652911644], // Langkofel
      [6.738765480902143, 7.2545879890301155, -1.3999974652911644], // Langkofel
      [6.738765480902143, 7.2545879890301155, -1.3999974652911644], // Langkofel
      [6.546330571247925, 7.487746882378045, -1.038846801690558],   // Tübingen
      [6.512042484669169, 7.522410119522816, -1.0033188284710568],  // Monbachtal
      [6.614086811005637, 7.446964199591195, -0.8925132304276173],  // Kaiserstuhl
      [6.51234340563848, 7.522106032495888, -1.0036454567854074],   // Neuhausen
      [6.5873660370311855, 7.440511929104505, -1.1159708450589603], // Federsee
      [6.51234340563848, 7.522106032495888, -1.0036454567854074],   // Neuhausen

  ]

  return (
      <div style={{ width: 'auto', height: '90vh' }}>
      <Canvas>
      {/* Set the starting camera position */}
      <PerspectiveCamera makeDefault position={[12, 17, -4]} fov={50} />

      {/* Adjust the control settings */}
      <OrbitControls
        rotateSpeed={0.01}             // Controls manual rotation speed
        minDistance={12}            // Prevents the camera from going through the globe
        maxDistance={100}           // Limits zoom out distance
        dampingFactor={0.5}         // Adds smoothness to the controls
      />
      {/* Pass the fetched coordinates to the Globe component */}
      {coordinates.length > 0 && <Globe coordinates={coordinates} />}
    </Canvas>
        </div>
  );
}